/* eslint-disable import/no-cycle */
import { formatMessage } from './helpers'
import { ConfigurationType } from './types'
import PhoneValidator, { PhoneFormatter } from './validators/phones'

const configuration: ConfigurationType[] = [
  {
    header: 'Etablissement',
    required: true,
    cell: {
      required: true,
      type: 'select',
    },
  },
  {
    header: 'Equipe',
    required: true,
    cell: {
      required: true,
      type: 'select',
    },
  },
  {
    header: 'matricule',
    required: true,
  },
  {
    header: 'Nom de naissance', // birth_name
    required: true,
  },
  {
    header: "Nom d'usage",
  },
  {
    header: 'Prénom',
    required: true,
    cell: {
      required: true,
    },
  },
  {
    header: 'Situation familiale', // family_situation
  },
  {
    header: 'email',
    required: true,
    cell: {
      type: 'email',
    },
  },
  {
    header: 'Téléphone mobile',
    required: true,
    cell: {
      validate: PhoneValidator,
      afterFormat: PhoneFormatter,
    },
  },
  {
    header: 'Téléphone fixe',
    required: true,
    cell: {
      validate: PhoneValidator,
      afterFormat: PhoneFormatter,
    },
  },
  {
    header: 'Sexe',
    required: true,
    cell: {
      trim: true,
      type: 'select',
      options: [
        'h',
        'male',
        'homme',
        'masculin',
        'f',
        'female',
        'femme',
        'féminin',
        'feminin',
      ],
    },
  },
  {
    header: 'N° de sécurité sociale',
    required: true,
    cell: {
      type: 'number',
    },
  },
  {
    header: 'Nationalité',
    required: true,
  },
  {
    header: 'Date de naissance',
    required: true,
    cell: {
      type: 'date',
    },
  },
  {
    header: 'Commune de naissance',
    required: true,
  },
  {
    header: 'Département de Naissance',
    required: true,
    cell: {
      type: 'number',
    },
  },
  {
    header: 'Pays de naissance', // birth_country
    required: true,
  },
  {
    header: 'Adresse',
    required: true,
  },
  {
    header: 'Code postal',
    required: true,
    cell: {
      type: 'number',
    },
  },
  {
    header: 'Ville',
    required: true,
  },
  {
    header: 'Pays de résidence', // country_of_residence
    required: true,
  },
  {
    header: 'Type de contrat',
    required: true,
    cell: {
      trim: true,
      strict: true,
      type: 'select',
      options: [
        'CDI',
        'CDD',
        'Stagiaire',
        'Saisonnier',
        'Apprentissage',
        'Intérimaire',
        'Extra',
      ],
    },
  },
  {
    header: "Nom de l'agence d'interim",
    required: false,
  },
  {
    header: "Adresse de l'agence d'interim",
    required: false,
  },
  {
    header: "Code postal de l'agence d'interim",
    required: false,
  },
  {
    header: "Ville de l'agence d'interim",
    required: false,
  },
  {
    header: 'Tuteur (adresse email)',
    required: false,
  },
  {
    header: 'Responsable hiérarchique (adresse email)',
    required: false,
  },
  {
    header: 'Temps contrat hebdo',
    cell: {
      type: 'number',
    },
  },
  {
    header: 'Nombre de jours travaillés par semaine',
    required: true,
    cell: {
      type: 'number',
      defaultValue: 5,
      validate: (cell: string) => {
        const workDays = Number(cell)

        if (!workDays || workDays < 8) {
          return null
        }

        return formatMessage('The working days should be between 1 to 7.')
      },
    },
  },
  {
    header: "Date d'embauche",
    cell: {
      type: 'date',
    },
  },
  {
    header: 'Date de sortie',
    required: true,
    cell: {
      type: 'date',
    },
  },
  {
    header: 'Intitulé du poste',
    required: true,
  },
  {
    header: 'Niveau',
    required: true,
  },
  {
    header: 'Échelon',
    required: true,
  },
  {
    header: 'Salaire brut mensuel',
    required: true,
    cell: {
      type: 'float',
    },
  },
  {
    header: 'IBAN',
    required: true,
    cell: {
      type: 'string',
    },
  },
  {
    header: 'Forfait jour. OUI/NON',
    required: true,
    cell: {
      type: 'select',
      defaultValue: 'non',
      options: ['oui', 'non'],
    },
  },
  {
    header: 'Type de document (travailleurs étrangers)',
    required: true,
    cell: {
      trim: true,
      strict: true,
      type: 'select',
      options: [
        'Carte de séjour : Compétences et talents',
        'Carte de résident longue durée-UE',
        'Carte de séjour temporaire : Vie privée et familiale',
        'Autorisation provisoire de séjour',
        'VLS-TS ou Carte de séjour "salarié"',
        'VLS-TS ou Carte de séjour "travailleur temporaire"',
        'Carte de séjour "travailleur saisonnier"',
        'Carte de séjour "salarié détaché ICT"',
        'VLS-TS ou Carte de séjour "étudiant"',
        'Carte de séjour "Etudiant Algérien"',
        'Carte de séjour "recherche d\'emploi ou création d\'entreprise"',
        'Récépissé portant la mention "autorise son titulaire à travailler"',
        'Visa vacances-travail',
        'Visa de court séjour Schengen',
        'Salarié détaché travaillant pour un prestataire de services européen',
        'Autorisation provisoire de séjour pour jeunes diplômés',
        'NIE',
        'Pasaporte',
        'Permiso de trabajo',
      ],
    },
  },
  {
    header: 'numéro de document',
    required: true,
  },
  {
    header: 'Date de début du document',
    required: true,
    cell: {
      type: 'date',
    },
  },
  {
    header: "Date d'expiration du document",
    required: true,
    cell: {
      type: 'date',
    },
  },
  {
    header: "Date d'autorisation de travail",
    required: false,
    cell: {
      type: 'date',
    },
  },
  {
    header: 'Date de dépôt de la demande d’autorisation de travail',
    required: false,
    cell: {
      type: 'date',
    },
  },
  {
    header: 'Mutuelle Oui/non',
    required: true,
    cell: {
      type: 'select',
      options: ['oui', 'non'],
    },
  },
  {
    header: 'Date dernière visite médicale',
    required: true,
    cell: {
      type: 'date',
    },
  },
  {
    header: 'Visite médicale renforcée. Oui/ non',
    required: true,
    cell: {
      type: 'select',
      options: ['oui', 'non'],
    },
  },
  {
    header: 'Rôle dans Combo',
    required: true,
    cell: {
      trim: true,
      type: 'select',
      defaultValue: 'Employé',
      options: [
        'employée',
        'employé',
        'employee',
        'manager',
        'directeur',
        'director',
        'admin',
        'owner',
      ],
    },
  },
  {
    header: 'Langue',
    required: true,
    cell: {
      defaultValue: 'FR',
      hasFirstOption: false,
      required: true,
      strict: true,
      type: 'select',
      options: ['FR', 'EN', 'ES', 'IT'],
    },
  },
  { header: "Contact d'urgence Prénom" },
  { header: "Contact d'urgence Nom" },
  { header: "Contact d'urgence Relation / Collaborateur" },
  { header: "Contact d'urgence Téléphone" },
  {
    header: 'Personne en situation de handicap',
    cell: {
      type: 'select',
      options: ['oui', 'non'],
      defaultValue: 'non',
    },
  },
  {
    header: 'Nombre de personnes à charge',
    cell: {
      type: 'number',
    },
  },
  {
    header: 'DNI - (Documento Nacional de Identidad)',
    cell: {
      type: 'string',
    },
  },
]

export default configuration
